import axios from "axios"

export default {
  async getProducts() {
    try {
      const products = await axios.get(
        `${process.env.PRODUCT_API_URL}/products`,
        { withCredentials: true }
      )
      return products
    } catch(error) {
      return error
    }
  },
  async getProduct(productId) {
    try {
      const product = await axios.get(
        `${process.env.PRODUCT_API_URL}/products/${productId}`,
        { withCredentials: true }
      )
      return product
    } catch(error) {
      return error
    }
  },
  async create(productParams) {
    try {
      const newProduct = await axios.post(
        `${process.env.PRODUCT_API_URL}/products`,
        { ...productParams },
        { withCredentials: true }
      )
      return newProduct
    } catch(error) {
      return error
    }
  },
  async update(productParams) {
    try {
      const updated = await axios.put(
        `${process.env.PRODUCT_API_URL}/products/${productParams.id}`,
        { ...productParams },
        { withCredentials: true }
      )
      return updated
    } catch(error) {
      return error
    }
  }
  // async get() {
  //   try {
  //     const userData = await axios.get(
  //       `${process.env.PRODUCT_API_URL}/users/current`,
  //       { withCredentials: true }
  //     )
  //     return userData
  //   } catch(error) {
  //     return error
  //   }
  // },
  // async logout() {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.PRODUCT_API_URL}/users/logout`,
  //       { withCredentials: true }
  //     )
  //     return response
  //   } catch(error) {
  //     return error
  //   }
  // }
}
