import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { createMuiTheme } from "@material-ui/core/styles"
import { selectCompanyTheme } from "../state/selectors"
import { get } from "lodash"

// A custom theme for this app
const overrides = theme => {
  return {
    props: {
      MuiTextField: {
        variant: "filled",
      },
      MuiFilledInput: {
        disableUnderline: true,
        margin: "dense",
      },
      MuiFormControl: {
        fullWidth: true,
      },
    },
    overrides: {
      MuiFilledInput: {
        root: {
          border: `1px solid ${theme.palette.grey[300]}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: "#fcfcfb",
          transition: theme.transitions.create(["border-color", "box-shadow"]),
          "&:hover": {
            backgroundColor: "#fff",
            borderColor: get(theme, "palette.primary.main"),
          },
          "&$focused": {
            backgroundColor: "#fff",
            borderColor: get(theme, "palette.primary.main"),
          },
        },
        focused: {},
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          marginRight: "10px",
        },
      },
    },
  }
}

export function CompanyTheme(props) {
  const companyTheme = selectCompanyTheme()
  return (
    <ThemeProvider theme={createMuiTheme({ ...companyTheme })}>
      {props.children}
    </ThemeProvider>
  )
}

export function CustomTheme(props) {
  const customTheme = theme =>
    createMuiTheme({
      ...theme,
      ...overrides(theme),
    })

  return <ThemeProvider theme={customTheme}>{props.children}</ThemeProvider>
}
