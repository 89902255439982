import React, { useState } from "react"
import { Link } from "gatsby"

import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
// import clsx from "clsx"
import { logoutUser } from "../state/actions"
import { makeStyles } from "@material-ui/core/styles"

import { useStateValue } from "../state"

import NavBar from "./navbar"
import LoginModal from "../components/partials/loginModal"
import RegisterModal from "../components/partials/registerModal"

import { get } from "lodash"

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
}))

export default function Controls(props) {
  const classes = useStyles()
  const [state, dispatch] = useStateValue()
  const [openLogin, setOpenLogin] = useState(false)
  const [openRegister, setOpenRegister] = useState(false)

  function handleClickOpenLogin() {
    setOpenLogin(true)
  }

  function handleClickOpenRegister() {
    setOpenRegister(true)
  }

  function handleClose() {
    setOpenLogin(false)
    setOpenRegister(false)
  }

  function handleLogout() {
    dispatch(logoutUser())
  }

  return (
    <>
      <NavBar>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            {get(state, "user.company.name") || "Dbunk Product Manager"}
          </Typography>
          {state.user.isLoggedIn ? (
            <>
              <Link to="editor">
                <Button color="inherit">Editor</Button>
              </Link>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button color="inherit" onClick={handleClickOpenLogin}>
                Login
              </Button>
              <Button color="inherit" onClick={handleClickOpenRegister}>
                Register
              </Button>
            </>
          )}
        </Toolbar>
      </NavBar>
      <LoginModal open={openLogin} onClose={handleClose} />
      <RegisterModal open={openRegister} onClose={handleClose} />
    </>
  )
}
