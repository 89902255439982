import React, { useEffect } from "react"
import { useStateValue } from "../state"
import EditorNav from "./editorNav"
import HomeNav from "./homeNav"
import { getUser, getPaymentProcessors } from "../state/actions"

export default function Controls({ isIndex }) {
  const [state, dispatch] = useStateValue()
  const { isLoggedIn } = state.user

  if (process.env.LIVE_MODE) return <></>

  useEffect(() => {
    let gotUser = false
    if (!state.user.isLoggedIn && !gotUser) {
      dispatch(getUser())
      dispatch(getPaymentProcessors())
      gotUser = true
    }
  }, [])

  return isLoggedIn && !isIndex ? <EditorNav /> : <HomeNav />
}
