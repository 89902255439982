import Dinero from "dinero.js"
import { pickBy, cloneDeep } from "lodash"
import moment from "moment"
import SafeEval from "safe-eval"

const STRIPE_RATE = 1 - 0.029
const STRIPE_FLAT_FEE = Dinero({ amount: 30 })
// placeholder until we get first First Insurance rates
const MONTHLY_RATE = 1 - 0.059

export function calculateFee(premium) {
  const convenienceFee = premium.divide(STRIPE_RATE).subtract(premium).add(STRIPE_FLAT_FEE)

  return convenienceFee
}

export function calculateMonthlyFee(premium) {
  const monthlyFee = premium.divide(MONTHLY_RATE).subtract(premium).add(STRIPE_FLAT_FEE)

  return monthlyFee
}

export function premiumEval(evalString, questions, values) {
  // filter form values by permittedInputs (ones marked as affectingPremium)
  const permittedInputs = questions.map(input => input.name)
  const permittedValues = pickBy(values, (_, key) =>
    permittedInputs.includes(key)
  )

  if (
    Object.keys(permittedValues).length !== permittedInputs.length ||
    !evalString ||
    !questions ||
    !values
  ) {
    return Dinero({ amount: 0 })
  }

  // clone permittedValues to prevent mutations by safeEval
  const evalContext = { moment, Dinero, ...cloneDeep(permittedValues) }

  const testString = `(function(params){ ${evalString} })({ moment, Dinero, ${permittedInputs.join(
    ","
  )}})`

  return SafeEval(testString, evalContext)
}
