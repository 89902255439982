import * as Yup from 'yup'

export const getNextId = (obj, name = "task") => {
  // Object.keys(tasks).map(key => key[key.length - 1])
  let keys = Object.keys(obj).map(key => key.split("-"))

  if (keys.length === 0) {
    keys = [[name, "0"]]
  }

  const typeName = keys[0][0]
  const increment = Math.max(...keys.map(key => key[1])) + 1
  return `${typeName}-${increment}`
}

export const getColumnType = templateName => {
  switch (templateName) {
    case "packages":
      return "packages"
    case "confirmation":
    case "checkout":
    case "terms":
      return "undroppable"
    default:
      return "question"
  }
}

export const generateValidations = page => {
  return Yup.object().shape({
    holes: Yup.array().min(1),
    amateurs: Yup.number().min(1)
  })
}