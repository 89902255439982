import axios from "axios"

export default {
  async getPaymentProcessors() {
    try {
      const paymentProcessors = await axios.get(
        `${process.env.PRODUCT_API_URL}/payment_processors`,
        { withCredentials: true }
      )
      return paymentProcessors
    } catch(error) {
      return error
    }
  },
  // async register(email, password) {
  //   try {
  //     const userData = await axios.post(
  //       `${process.env.PRODUCT_API_URL}/users/register`,
  //       { email, password },
  //       { withCredentials: true }
  //     )
  //     return userData
  //   } catch(error) {
  //     return error
  //   }
  // },
  // async get() {
  //   try {
  //     const userData = await axios.get(
  //       `${process.env.PRODUCT_API_URL}/users/current`,
  //       { withCredentials: true }
  //     )
  //     return userData
  //   } catch(error) {
  //     return error
  //   }
  // },
  // async logout() {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.PRODUCT_API_URL}/users/logout`,
  //       { withCredentials: true }
  //     )
  //     return response
  //   } catch(error) {
  //     return error
  //   }
  // }
}
