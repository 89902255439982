import React from "react"
import PropTypes from "prop-types"
import TextFieldMaterial from "@material-ui/core/TextField"

import { getError } from "../../../util/forms"

// import { maskAsInteger } from "utils/forms"

const TextField = ({
  field,
  form,
  label,
  className,
  disabled,
  placeholder,
  style,
  description,
  ...rest
}) => {
  const error = getError(form, field.name)

  return (
    <>
      {description && <div>{description}</div>}
      <TextFieldMaterial
        error={!!error}
        label={label}
        {...field}
        helperText={error}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        {...rest}
      />
    </>
  )
}

TextField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

TextField.defaultProps = {
  label: "",
  disabled: false,
  className: undefined,
}

export default TextField
