import {
  cloneDeep,
  set,
  values,
  map,
  mapValues,
  mapKeys,
  omitBy,
  findKey,
  get,
  memoize,
  pickBy,
} from "lodash"
import { useStateValue } from "./index"
import moment from "moment"
import { premiumEval, calculateFee, calculateMonthlyFee } from "../util/premium"

function selectCurrentState() {
  const [currentState] = useStateValue()
  return currentState
}

export function formToInputs(product) {
  if (!get(product, "jsondata.columns")) return undefined

  const { columns, tasks, columnOrder } = product.jsondata
  const newColumns = cloneDeep(columns)
  const newTasks = cloneDeep(tasks)
  const newColumnOrder = [...columnOrder]

  // denormalize columns and tasks
  const denormalizedColumns = newColumnOrder.map(colId => newColumns[colId])
  const result = denormalizedColumns.map(column =>
    set(
      column,
      "taskIds",
      column.taskIds.map(taskId => {
        let result = newTasks[taskId]
        if (result.taskIds) {
          result.taskIds = result.taskIds.map(id => newTasks[id])
        }
        return result
      })
    )
  )

  return result
}

export function selectCompanyTheme() {
  const state = selectCurrentState()
  return get(state, "user.company.theme", {})
}

export function selectInitialValues(product) {
  // const state = selectCurrentState()
  const questions = get(product, "jsondata", {})
  const { tasks, columns } = questions
  const newTasks = { ...tasks }

  // inject a question for terms if page exists
  const hasTermsPage = findKey(columns, page => page.template === "terms")

  if (hasTermsPage) {
    newTasks["agreedTerms"] = {
      id: "agreedTerms",
      type: "checkbox",
      label: "agreed to terms",
      name: "agreedTerms",
    }
  }

  // remove package type questions
  let fieldArrayTasks = []
  const mappedFieldArrays = map(newTasks, task => {
    const result = { ...task }
    if (task.taskIds && task.type === "fieldArray") {
      const newTaskIds = task.taskIds.map(id => newTasks[id])
      fieldArrayTasks = fieldArrayTasks.concat(task.taskIds)
      set(result, "taskIds", newTaskIds)
    }
    return result
  })
  const validTasks = omitBy(
    mappedFieldArrays,
    task => task.type === "package" || fieldArrayTasks.includes(task.id)
  )

  const keyedValues = mapKeys(validTasks, kv => kv.name)
  const defaultValues = mapValues(keyedValues, getDefaultValue)

  return defaultValues
}

export function selectConfirmationQuestions(product) {
  const allInputs = formToInputs(product)
  const underwritingQuestions = allInputs.filter(
    page => page.template === "configure" || page.template === "question"
  )

  return underwritingQuestions
}

export function selectPageTitles(product) {
  // const state = selectCurrentState()
  const steps = get(product, "jsondata.columns", {})
  return Object.keys(steps)
}

export const selectPremiumBreakdown = memoize(calculatePremium, (...args) => {
  const permittedInputs = selectPremiumAffectingQuestions(args[1]).map(
    input => input.name
  )
  const permittedValues = pickBy(args[0], (_, key) =>
    permittedInputs.includes(key)
  )

  return JSON.stringify(permittedValues)
})

export function calculatePremium(values, product) {
  const expression = get(product, "premium_expression", "")
  const questions = selectPremiumAffectingQuestions(product)
  const premium = premiumEval(expression, questions, values)
  const convenienceFee = calculateFee(premium)
  const monthlyFee = calculateMonthlyFee(premium)
  const total = premium.add(convenienceFee)
  const totalMonthly = premium.add(monthlyFee)

  const result = {
    premium,
    convenienceFee,
    monthlyFee,
    total,
    totalMonthly,
  }

  return result
}

export function selectPremiumAffectingQuestions(product) {
  // const state = selectCurrentState()
  const questions = get(product, "jsondata.tasks", {})
  const questionArray = values(questions)
  const mappedQuestions = questionArray.map(question => {
    let result = cloneDeep(question)
    if (question.type === "fieldArray" && !!result.taskIds) {
      result.taskIds = result.taskIds.map(id => cloneDeep(questions[id]))
    }
    return result
  })

  // filter any premium-affecting ones
  return mappedQuestions.filter(question => !!question.affectsPremium)
}

export function selectCurrentProduct() {
  const state = selectCurrentState()
  return get(state, "editProduct")
}

export function getDefaultValue(task) {
  const { type } = task
  switch (type) {
    case "radioButton":
    case "dropdown": {
      // retrieve source for select component
      return get(task, "options[0].value", [])
    }
    case "number": {
      return "0"
    }
    case "text": {
      return ""
    }
    case "date": {
      return moment()
    }
    case "addressBlock": {
      return {
        line1: "",
        line2: "",
        country: "Canada",
        city: "",
        state: "",
        postal_code: "",
      }
    }
    case "yesNo":
    case "checkbox": {
      return false
    }
    case "fieldArray": {
      return []
    }
    default: {
      return ""
    }
  }
}
