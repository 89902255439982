import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import { Helmet } from "react-helmet"
import { StateProvider } from "../state"
import { CompanyTheme, CustomTheme } from "../styles/theme"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import Controls from "./controls"
import Footer from "./footer"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    minHeight: "calc(100vh - 172px)", //TODO: Sticky footer, but find better way to adjust for footer height
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "calc(100vw - 73px)",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}))

export default function Layout(props) {
  const classes = useStyles()

  // TODO: remove hack for rendering homeNav
  const isIndex = props["*"] === ""

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <StateProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CompanyTheme>
            <CustomTheme>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <div className={classes.root}>
                <CssBaseline />
                <Controls isIndex={isIndex} />
                {process.env.LIVE_MODE ? (
                  <div style={{ width: "100%", minHeight: "100vh" }}>
                    {props.children}
                  </div>
                ) : (
                  <div>
                    <div className={classes.toolbar} />
                    <main className={classes.content}>{props.children}</main>
                  </div>
                )}
              </div>
              <Footer />
            </CustomTheme>
          </CompanyTheme>
        </MuiPickersUtilsProvider>
      </StateProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}
