import axios from "axios"

export default {
  async update(theme) {
    try {
      const updated = await axios.put(
        `${process.env.PRODUCT_API_URL}/company`,
        { theme },
        { withCredentials: true }
      )
      return updated
    } catch(error) {
      return error
    }
  }
}
