import User from "../api/user"
import Product from "../api/product"
import Company from "../api/company"
import PaymentProcessor from "../api/paymentProcessor"

export const loginUser = (email, password) => {
  return async dispatch => {
    dispatch({ type: "startLoading" })
    const response = await User.login(email, password)
    if (response instanceof Error) {
    } else {
      dispatch(setUser(response.data.user))
    }
    dispatch({ type: "stopLoading" })
  }
}

export const getUser = () => {
  return async dispatch => {
    const response = await User.get()
    if (response instanceof Error) {
      
    } else {
      dispatch(setUser(response.data))
    }
  }
}

export const getPaymentProcessors = () => {
  return async dispatch => {
    const response = await PaymentProcessor.getPaymentProcessors()
    if (response instanceof Error) {
      
    } else {
      dispatch(setPaymentProcessors(response.data))
    }
  }
}

export const logoutUser = () => {
  return async dispatch => {
    await User.logout()
    dispatch({ type: "LOGOUT_USER" })
  }
}

export const registerUser = (email, password) => {
  return async dispatch => {
    dispatch(startLoading())
    const response = await User.register(email, password)
    if (response instanceof Error) {
    } else {
      dispatch(setUser(response.data.user))
    }
    dispatch(stopLoading())
  }
}

export const setUser = user => ({
  type: "SET_USER",
  user,
})

export const setPaymentProcessors = paymentProcessors => ({
  type: "SET_PAYMENT_PROCESSORS",
  paymentProcessors,
})

export const startLoading = () => ({
  type: "startLoading"
})

export const stopLoading = () => ({
  type: "stopLoading"
})

export const setProducts = products => ({
  type: "SET_PRODUCTS",
  products,
})

export const setEditProduct = product => ({
  type: "SET_EDIT_PRODUCT",
  product,
})

export const updateTheme = theme => ({
  type: "UPDATE_THEME",
  theme,
})

export const appendProduct = product => ({
  type: "APPEND_PRODUCT",
  product,
})

export const saveProduct = (product) => {
  return async dispatch => {
    dispatch(startLoading())
    const response = await Product.update(product)
    if (response instanceof Error) {
    } else {
      dispatch(setEditProduct(response.data))
    }
    dispatch(stopLoading())
  }
}

export const saveTheme = (theme) => {
  return async dispatch => {
    dispatch(startLoading())
    const response = await Company.update(theme)
    if (response instanceof Error) {
    } else {
      dispatch(updateTheme(theme))
    }
    dispatch(stopLoading())
  }
}

export const getProducts = () => {
  return async dispatch => {
    dispatch(startLoading())
    const response = await Product.getProducts()
    if (response instanceof Error) {
    } else {
      dispatch(setProducts(response.data))
    }
    dispatch(stopLoading())
  }
}

export const getProduct = productId => {
  return async dispatch => {
    dispatch(startLoading())
    const response = await Product.getProduct(productId)
    if (response instanceof Error) {
    } else {
      dispatch(setEditProduct(response.data))
    }
    dispatch(stopLoading())
  }
}

export const createProduct = (productParams) => {
  return async dispatch => {
    dispatch(startLoading())
    const response = await Product.create(productParams)
    if (response instanceof Error) {
    } else {
      dispatch(appendProduct(response.data))
    }
    dispatch(stopLoading())
  }
}

export const togglePublished = (productId) => {
  return {
    type: "TOGGLE_PUBLISHED",
    productId,
  }
}