import React from "react"
import { get } from "lodash"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import dbunkPoweredWhite from "../images/dbunk-powered-white.png"
import dbunkPoweredBlack from "../images/dbunk-powered-black.png"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    zIndex: theme.zIndex.drawer + 1,
    position: "relative",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    flexGrow: 1,
  },
  logo: {
    width: "80%",
    float: "right",
  },
  copyright: {
    float: "right",
    textAlign: "right",
    paddingTop: 10,
  },
  header: {
    fontWeight: 600,
    textTransform: "uppercase",
  },
}))

const Footer = () => {
  const classes = useStyles()
  const theme = useTheme()
  let logo = dbunkPoweredBlack

  if (get(theme, "palette.primary.contrastText") === "#fff") {
    logo = dbunkPoweredWhite
  }

  return (
    <footer className={classes.root}>
      <Box maxWidth="sm">
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography variant="body1" className={classes.header}>
              about
            </Typography>
            <Typography variant="body2">
              Dbunk InsureTech Inc. is an Insurance Technology firm committed to
              debunking those myths by accelerating digital distribution
              throughout the entire insurance value chain.
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" className={classes.header}>
              shop
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                All Products
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                Residential
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                Commercial
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" className={classes.header}>
              Information
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                About Us
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                FAQ
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                Blog
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                Terms & Conditions
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link color="inherit" href="http://dbunk.io">
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" className={classes.header}>
              Contact
            </Typography>
            <Typography variant="body2">
              Dbunk InsureTech Inc.
              <br />
              320-1111 Melville Street
              <br />
              Vancouver, British
              <br />
              Columbia Canada V6E 3V6
            </Typography>
          </Grid>
          <Grid item xs>
            <img src={logo} alt="Powered by dbunk" className={classes.logo} />
            <Typography variant="caption" className={classes.copyright}>
              {"Copyright © "}
              {new Date().getFullYear()}
              <br />
              <Link color="inherit" href="http://dbunk.io">
                Dbunk InsureTech Inc.
              </Link>
              <br />
              All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </footer>
  )
}

export default Footer
